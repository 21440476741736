import {
  mdiClockTimeFiveOutline,
  mdiCogOutline,
  mdiCarOutline,
  mdiStoreOutline,
  mdiBedOutline,
  mdiOfficeBuilding,
  mdiHospitalBuilding,
  mdiCake,
  mdiStarOutline,
  mdiAccountCircleOutline,
  mdiEmoticonHappyOutline,
  mdiHelpCircleOutline,
  mdiLockOutline,
  mdiEmailOutline,
  mdiPlusCircleOutline,
  mdiBookOpenVariant,
  mdiCheckDecagramOutline,
  mdiStorefrontOutline,
  mdiTranslateVariant,
  mdiClipboardTextOutline,
  mdiLogout,
} from "@mdi/js";

export const useLists = () => {
  return {
    loadInterests() {
      return [
        { name: "Barca", img: "Barca.svg" },
        { name: "Borgo", img: "Borgo.svg" },
        { name: "Campeggio", img: "Campeggio.svg" },
        { name: "Cibo", img: "Cibo.svg" },
        { name: "Città", img: "Citta.svg" },
        { name: "Cultura", img: "Cultura.svg" },
        { name: "Dune", img: "Dune.svg" },
        { name: "Esperienza", img: "Esperienza.svg" },
        { name: "Festival", img: "Festival.svg" },
        { name: "Fiume", img: "Fiume.svg" },
        { name: "Foresta", img: "Foresta.svg" },
        { name: "Lago", img: "Lago.svg" },
        { name: "Mare", img: "Mare.svg" },
        { name: "Montagna", img: "Montagna.svg" },
        { name: "MovieTour", img: "MovieTour.svg" },
        { name: "Natura", img: "Natura.svg" },
        { name: "OnTheRoad", img: "OnTheRoad.svg" },
        { name: "ParcoNazionale", img: "ParcoNazionale.svg" },
        { name: "Relax", img: "Relax.svg" },
        { name: "Religione", img: "Religione.svg" },
        { name: "Safari", img: "Safari.svg" },
        { name: "Shopping", img: "Shopping.svg" },
        { name: "Sport", img: "Sport.svg" },
        { name: "Trekking", img: "Trekking.svg" },

      ];
    },
    loadAddInfoStage() {
      return [

        {
          name: "officialLanguage",
          options: useLanguages()
        },
        {
          name: "Lingue parlate",
          options: useLanguages(),
        },
        { name: "currency", options: ["Afghanis", "Ariary", "Australia Dollars", "Baht", "Balboa", "Birr", "Bolivares", "Bolivianos", "Cedis", "Colones", "Communauté Financie 're Africaine Francs", "Communauté Financie're Africaine Francs", "Comptoirs Français du Pacifique Francs", "Convertible Marka", "Dalasi", "Denars", "Denmark Kroner", "Dinars", "Dirhams", "Dobras", "Dollars", "Dong", "Drams", "East Caribbean Dollars", "Emalangeni", "Escudos", "Ethiopia Birr", "Euro", "Forint", "Francs", "Gold Cordobas", "Gourdes", "Guarani", "Guilders", "Hryvnia", "India Rupeess", "Indonesia Rupiahs", "Kina", "Kips", "Koruny", "Kroner", "Kronor", "Kronur", "Krooni", "Kuna", "Kwacha", "Kwachas", "Kwanza", "Kyats", "Lari", "Lati", "Lei", "Leke", "Lempiras", "Leones", "Leva", "Liras", "Liri", "Litai", "Luigini", "Maloti", "Manats", "Meticais", "Morocco Dirhams", "Nairas", "Nakfa", "Netherlands Antilles Guilders", "New Dollars", "New Shekels", "New Zealand Dollars", "Ngultrum", "Norway Kroner", "Nuevos Soles", "Ouguiyas", "Ounces", "Pa'anga", "Patacas", "Pesos", "Pounds", "Pulas", "Quetzales", "Rand", "Real", "Rials", "Riels", "Ringgits", "Riyals", "Rubles", "Rufiyaa", "Rupees", "Rupiahs", "Russia Rubles", "Shillings", "Somoni", "Soms", "South Africa Rand", "Special Drawing Rights", "Sums", "Switzerland Francs", "Taka", "Tala", "Tenge", "Tolars", "Tugriks", "Tuvalu Dollars", "United Kingdom Pounds", "United States Dollars", "Vatu", "Won", "Yen", "Yuan Renminbi", "Zimbabwe Dollars", "Zlotych"] },
        { name: "jetLag", options: ["UTC-12", "UTC-11 (BEST – Bering Standard Time)", "UTC-10 (HST – Hawaii-Aleutian Standard Time)", "UTC-9:30", "UTC-9 (AKST – Alaska Standard Time)", "UTC-8 (PST – Pacific Standard Time)", "UTC-7 (MST – Mountain Standard Time)", "UTC-6 (CST – Central Standard Time)", "UTC-5 (EST – Eastern Standard Time)", "UTC-4 (AST – Atlantic Standard Time)", "UTC-3:30 (NST – Newfoundland Standard Time)", "UTC-3", "UTC-2", "UTC-1", "UTC+0 (WET – West European Time)", "UTC+1 (CET – Central European Time)", "UTC+2 (EET – East European Time)", "UTC+3 (MSK – Moscow Time)", "UTC+3:30", "UTC+4", "UTC+4:30", "UTC+5", "UTC+5:30 (IST – Indian Standard Time)", "UTC+5:45", "UTC+6", "UTC+6:30", "UTC+7", "UTC+8 (AWST – Australian Western Standard Time)", "UTC+8:45", "UTC+9", "UTC+9:30 (ACST – Australian Central Standard Time)", "UTC+10 (AEST – Australian Eastern Standard Time)", "UTC+10:30", "UTC+11", "UTC+12", "UTC+12:45", "UTC+13", "UTC+13:45, UTC+14"] },
        { name: "driverSide", options: ["Destra", "Sinistra"] },
        { name: "climate", options: ["Alpino", "Boreale", "Boreale Delle Foreste", "Continentale", "Della Savana", "Della Tundra", "Desertico", "Equatoriale", "Glaciale", "Mediterraneo", "Monsonico", "Nivale", "Oceanico", "Polare", "Sinico", "Steppico", "Subartico", "Subtropicale", "Temperato", "Temperato Umido", "Transiberiano", "Tropicale"] },
        { name: "recommendedVisitTimes", options: ["Settimane", "Giorni", "Ore"] },
        { name: "Orario di apertura", type: 'hour' },
        { name: "Orario di chiusura", type: 'hour' },
        { name: "Mezzo più veloce", options: ["Aereo", "Aliscafo", "Asino", "Auto", "Autobus", "Barca", "Barca a vela", "Bicicletta", "Cammello", "Catamarano", "Cavallo", "Elicottero", "Filobus", "Funivia", "Gondola", "Jeepney", "Jet", "Jukung", "Long tail boat", "Metropolitana", "Mongolfiera", "Motorino", "Motoscafo", "Motoslitta", "Piedi", "Rot Songthaew", "Saleng", "Seggiovia", "Slitta", "Taxi", "Traghetto", "Tram", "Treno", "Tuk tuk", "Vaporetto"] },
        { name: "Mezzo più economico", options: ["Aereo", "Aliscafo", "Asino", "Auto", "Autobus", "Barca", "Barca a vela", "Bicicletta", "Cammello", "Catamarano", "Cavallo", "Elicottero", "Filobus", "Funivia", "Gondola", "Jeepney", "Jet", "Jukung", "Long tail boat", "Metropolitana", "Mongolfiera", "Motorino", "Motoscafo", "Motoslitta", "Piedi", "Rot Songthaew", "Saleng", "Seggiovia", "Slitta", "Taxi", "Traghetto", "Tram", "Treno", "Tuk tuk", "Vaporetto"] },
        { name: "Prezzo d'ingresso", type: 'short-text' },
        { name: "Accessibilità sedia a rotelle o passeggino", type: 'flag' },
        { name: "Area in manutenzione", type: 'flag' },
        { name: "Wi-Fi pubblico", type: 'flag' },
        { name: "Presenza punto ristoro", type: 'flag' },
        { name: "Sito web", type: 'text' },
        { name: "Contatto telefonico", type: 'short-text' },
        { name: "Consigli della community", type: "text" },
        { name: "Attrezzature consigliate", type: "text" },

      ];
    },
    loadOptions() {
      return [
        [
          /*{
            name: "home",
            icon: "/imgs/turtle-icon-white.svg",
            type: "iconFile",
            to: "/",
          },
          {
            name: "newItinerary",
            icon: mdiPlusCircleOutline,
            type: "iconMdi",
            to: "/itinerary",
          },
          {
            name: "titleItineraries",
            icon: mdiBookOpenVariant,
            type: "iconMdi",
            to: "/itineraries",
          },*/
          {
            name: "myInterests",
            icon: mdiStarOutline,
            type: "iconMdi",
            to: "/interests",
          },
          {
            name: "language",
            icon: mdiTranslateVariant,
            type: "iconMdi",
            to: "/language",
          },
          {
            name: "account",
            icon: mdiAccountCircleOutline,
            type: "iconMdi",
            to: "/account",
          },
          {
            name: "logout",
            icon: mdiLogout,
            type: "iconMdi",
            to: "/logout",
          },
        ],
        [
          {
            name: "whoWeAre",
            icon: mdiEmoticonHappyOutline,
            type: "iconMdi",
            to: "/infos",
          },
          {
            name: "faq",
            icon: mdiHelpCircleOutline,
            type: "iconMdi",
            to: "/faq",
          },
          {
            name: "privacyPolicy",
            icon: mdiLockOutline,
            type: "iconMdi",
            to: "/privacy",
          },
          {
            name: "termsConditions",
            icon: mdiClipboardTextOutline,
            type: "iconMdi",
            to: "/terms",
          },
          {
            name: "contactUs",
            icon: mdiEmailOutline,
            type: "iconMdi",
            to: "/contact",
          },
        ],
      ];
    },
  };
};
