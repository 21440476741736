export const useLanguages = () => {
  return [
    "Abazino",
    "Adighè",
    "Afar",
    "Afrikaans",
    "Agul",
    "Albanese",
    "Altai",
    "Amarico",
    "Arabo",
    "Araona",
    "Armeno",
    "Assamese",
    "Avaro",
    "Aymara",
    "Azero",
    "Balinese",
    "Baschira",
    "Basco",
    "Basso-Tedesco",
    "Baure",
    "Bengalese",
    "Bengali",
    "Berbero",
    "Bésiro",
    "Bielorusso",
    "Birmano",
    "Bislama",
    "Bosniaco",
    "Bulgaro",
    "Buriata",
    "Cabardo",
    "Calmucco",
    "Canichana",
    "Caraciai-Balcaro",
    "Catalano",
    "Cavineño",
    "Cayubaba",
    "Ceceno",
    "Ceco",
    "Chácobo",
    "Chakasso",
    "Chamorro",
    "Chewa",
    "Chichewa",
    "Chimán",
    "Cinese",
    "Circasso",
    "Ciuvascio",
    "Comoriana",
    "Coreano",
    "Creolo Delle Seychelles",
    "Creolo Haitiano",
    "Croato",
    "Danese",
    "Dargwa",
    "Dari",
    "Dhivehi",
    "Dzongkha",
    "Ebraico",
    "Ese Ejja",
    "Estone",
    "Figiano",
    "Filippino",
    "Francese",
    "Francoprovenzale",
    "Frisone Occidentale",
    "Frisone Orientale",
    "Frisone Settentrionale",
    "Friulano",
    "Galiziano",
    "Georgiano",
    "Giapponese",
    "Giavanese",
    "Gilbertese",
    "Greco",
    "Guaraní",
    "Guarasu'We",
    "Guarayu",
    "Gujarati",
    "Hawaiano",
    "Hindi",
    "Hindi Figiano",
    "Hiri Motu",
    "Hmong",
    "Indonesiano",
    "Inglese",
    "Inguscio",
    "Irlandese",
    "Islandese",
    "Italiano",
    "Itonama",
    "Kalanga",
    "Kazako",
    "Khmer",
    "Kinyarwanda",
    "Kirghiso",
    "Kirundi",
    "Komi",
    "Ladino",
    "Lak",
    "Lao",
    "Latino",
    "Leco",
    "Lesga",
    "Lettone",
    "Libras",
    "Lingua Aceh",
    "Lingua Ndebele Del Nord",
    "Lingua Shona",
    "Lingue Sorabe",
    "Lituano",
    "Lussemburghese",
    "Macedone",
    "Machajuyai-Kallawaya",
    "Machineri",
    "Malayalam",
    "Malese",
    "Malgascio",
    "Maltese",
    "Mandarino Standard",
    "Māori",
    "Marathi",
    "Mari",
    "Maropa",
    "Marshallese",
    "Mirandese",
    "Mojeño-Ignaciano",
    "Mojeñotrinitario",
    "Mongolo",
    "Mordvino",
    "Moré",
    "Mosetén",
    "Movima",
    "Nambya",
    "Nauruano",
    "Ndau",
    "Ndebele",
    "Nepalese",
    "Nogai",
    "Norvegese",
    "Occitano",
    "Olandese",
    "Oriya",
    "Oromo",
    "Osseto",
    "Pacawara",
    "Palauano",
    "Papiamento",
    "Papua New Guinean Sign Language",
    "Pashto",
    "Persiano",
    "Polacco",
    "Portoghese",
    "Punjabi",
    "Puquina",
    "Quechua",
    "Romancio",
    "Romaní",
    "Romeno",
    "Russo",
    "Ruteno",
    "Rutulo",
    "Sacha",
    "Samoano",
    "Santali",
    "Sardo",
    "Sena",
    "Sentinelese",
    "Serbo",
    "Sesotho",
    "Sesotho Del Nord",
    "Shangani",
    "Sichuan Yi",
    "Singalese",
    "Sirionó",
    "Slovacco",
    "Sloveno",
    "Somali",
    "Somalo",
    "Sotho Del Nord",
    "Spagnolo",
    "Svedese",
    "Swahili",
    "Swati",
    "Swazi",
    "Tabasarano",
    "Tacana",
    "Tagico",
    "Tamil",
    "Tapiete",
    "Tat",
    "Tataro",
    "Tedesco",
    "Telugu",
    "Tetum",
    "Thai",
    "Tibetano",
    "Tigrino",
    "Tigrino",
    "Tok Pisin",
    "Tonga",
    "Tongano",
    "Toromona",
    "Tosa",
    "Tsakhur",
    "Tsonga",
    "Tswana",
    "Turco",
    "Turkmeno",
    "Tuvaluano",
    "Tuvano",
    "Ucraino",
    "Udmurto",
    "Uiguro",
    "Ungherese",
    "Urdu",
    "Uru-Chipaya",
    "Uzbeko",
    "Venda",
    "Vietnamita",
    "Weenhayek",
    "Wolof",
    "Xhosa",
    "Yaminawa",
    "Yiddish",
    "Yuki",
    "Yuracaré",
    "Zamuco",
    "Zhuang",
    "Zulu"
  ]
}
